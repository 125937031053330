import React from "react";
import { graphql } from "gatsby";
import loadable from "@loadable/component";

import { GatsbySeo, FAQJsonLd, ArticleJsonLd } from "gatsby-plugin-next-seo";
import Layout from "../components/Layout";
import {
  generateFaqItems,
  getFirstImageFromAllContentComponents,
  getFirstParagraphFromAllContentComponents,
} from "../utils/schemes";

// Loadable Components
const FaqComponent = loadable(() => import("../components/FaqComponent"));
const CasinoList = loadable(() => import("../components/CasinoList"));
const ContentComponent = loadable(() => import("../components/ContentComponent"));

const slugWithoutImages = ["contact-us", "cookie-policy", "privacy-policy", "terms-and-conditions"];

const WebsitePage = ({ data, location }) => {
  const pageData = data?.contentfulWebsitePage;

  const components = pageData?.components;

  const firstParagraph = getFirstParagraphFromAllContentComponents(
    components,
    data?.contentfulWebsitePage?.pageName === "Main Page" ? "home" : null
  );
  let firstImage;
  if (slugWithoutImages.includes(pageData.slug)) {
    firstImage =
      "https://images.ctfassets.net/2wa9u3eol8ip/2vkBFzoHePZ9lzSaXpL9gw/5e79aaffe39bbf35ce40e229fcc34877/rahapelit.webp";
  } else {
    firstImage = getFirstImageFromAllContentComponents(components);
  }

  const headline =
    data?.contentfulWebsitePage?.pageName === "Main Page"
      ? "The Ultimate Online Casino List: Reviews, Ratings & Rewards"
      : data?.contentfulWebsitePage?.pageName;

  const slugId = pageData.slug === "/" ? "" : `${pageData.slug}/`;

  return (
    <Layout location={location}>
      <div className="content container" style={{ minHeight: "700px" }}>
        {pageData?.pageName && (
          <div className="entry-header has-text-align-center header-footer-group">
            <div className="entry-header-inner section-inner medium">
              <h1 className="has-text-align-center entry-title">{pageData?.pageName}</h1>
            </div>
          </div>
        )}
        <ArticleJsonLd
          url={`https://kasinotilmanlisenssia.com/${slugId}`}
          headline={headline}
          images={[firstImage]}
          datePublished={pageData.createdAt}
          dateModified={pageData.updatedAt}
          proficiencyLevel="Expert"
          authorName="Kasinot ilman lisenssiä"
          publisherName="Kasinot ilman lisenssiä"
          publisherLogo="https://images.ctfassets.net/2wa9u3eol8ip/2vkBFzoHePZ9lzSaXpL9gw/5e79aaffe39bbf35ce40e229fcc34877/rahapelit.webp"
          description={firstParagraph}
          overrides={{
            "@type": "TechArticle", // set's this as a blog post.
          }}
        />
        {components?.map((component) => {
          switch (component.__typename) {
            case "ContentfulSeoComponent":
              return (
                <GatsbySeo
                  title={component?.seoTitle}
                  description={component?.seoDescription?.seoDescription}
                  canonical={
                    component.seoSlug === "/"
                      ? "https://kasinotilmanlisenssia.com"
                      : `https://kasinotilmanlisenssia.com/${component.seoSlug}/`
                  }
                />
              );
            case "ContentfulFaqComponent":
              return (
                <>
                  <FAQJsonLd questions={generateFaqItems(component?.faqItems)} />
                  <FaqComponent faqItems={component?.faqItems} title={component?.title} />
                </>
              );
            case "ContentfulContentComponent":
              return <ContentComponent data={component?.contentBody} />;
            default:
              return <></>;
          }
        })}
      </div>
    </Layout>
  );
};

export default WebsitePage;

export const query = graphql`
  query page($id: String!) {
    contentfulWebsitePage(id: { eq: $id }) {
      id
      pageName
      createdAt(formatString: "YYYY-MM-DD")
      updatedAt(formatString: "YYYY-MM-DD")
      slug
      components {
        ... on ContentfulSeoComponent {
          __typename
          seoSlug
          seoTitle
          seoDescription {
            seoDescription
          }
        }
        ... on ContentfulContentComponent {
          __typename
          contentBody {
            childrenMarkdownRemark {
              html
            }
          }
        }
        ... on ContentfulCasinoList {
          __typename
          title
          casinoList {
            bonukset
            description
            features
            ilmaiskierrokset
            link
            lisenssi
            pelienMr
            logo {
              gatsbyImageData
            }
            name
            rating
          }
        }
        ... on ContentfulFaqComponent {
          __typename
          title
          faqItems {
            faqQuestion
            faqAnswer {
              faqAnswer
            }
          }
        }
      }
    }
  }
`;
